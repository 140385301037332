//        ___          ___          ___                       ___          ___
//       /__/\        /__/\        /__/\        _____        /  /\        /  /\
//       \  \:\       \  \:\      |  |::\      /  /::\      /  /:/_      /  /::\
//        \  \:\       \  \:\     |  |:|:\    /  /:/\:\    /  /:/ /\    /  /:/\:\
//    _____\__\:\  ___  \  \:\  __|__|:|\:\  /  /:/~/::\  /  /:/ /:/_  /  /:/~/:/
//   /__/::::::::\/__/\  \__\:\/__/::::| \:\/__/:/ /:/\:|/__/:/ /:/ /\/__/:/ /:/___
//   \  \:\~~\~~\/\  \:\ /  /:/\  \:\~~\__\/\  \:\/:/~/:/\  \:\/:/ /:/\  \:\/:::::/
//    \  \:\  ~~~  \  \:\  /:/  \  \:\       \  \::/ /:/  \  \::/ /:/  \  \::/~~~~
//     \  \:\       \  \:\/:/    \  \:\       \  \:\/:/    \  \:\/:/    \  \:\
//      \  \:\       \  \::/      \  \:\       \  \::/      \  \::/      \  \:\
//       \__\/        \__\/        \__\/        \__\/        \__\/        \__\/



//*
// A button that has no ellipse, but instead shows the footnote's number on the
// page. Note that the number will be reset to 1 depending on the selector passed
// to bigfoot's `numberResetSelector` option.

// @since 2.1.0
// @author Chris Sauve

.bigfoot-footnote__button {
  position: relative;
  height: $button-height;
  width: 1.5em;
  border-radius: $button-height/2;

  &:after {
    // CONTENT
    content: attr(data-footnote-number);

    // POSITION
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

    // DISPLAY AND SIZING
    display: block;

    // TEXT
    font-size: $button-height*0.6;
    font-weight: bold;
    color: $button-color-text;

    // TRANSITIONS
    transition: color $popover-transition-default-duration $popover-transition-default-timing-function;
  }

  &:hover,
  &.is-active {
    &:after {
      color: white;
    }
  }
}

.bigfoot-footnote__button__circle {
  display: none;
}
