

/* Social Icons */ 
  
.fa-behance,
.fa-behance-square {
  color: $behance-color;
}

.fa-bitbucket {
  color: $bitbucket-color;
}

.fa-dribbble,
.fa-dribble-square {
  color: $dribbble-color;
}

.fa-facebook,
.fa-facebook-square,
.fa-facebook-f {
  color: $facebook-color;
}

.fa-flickr {
  color: $flickr-color;
}

.fa-foursquare {
  color: $foursquare-color;
}

.fa-github,
.fa-github-alt,
.fa-github-square {
  color: $github-color;
}

.fa-gitlab {
  color: $gitlab-color;
}

.fa-instagram {
  color: $instagram-color;
}

.fa-lastfm,
.fa-lastfm-square {
  color: $lastfm-color;
}

.fa-linkedin,
.fa-linkedin-in {
  color: $linkedin-color;
}

.fa-mastodon,
.fa-mastodon-square {
  color: $mastodon-color;
}

.fa-pinterest,
.fa-pinterest-p,
.fa-pinterest-square {
  color: $pinterest-color;
}

.fa-reddit {
  color: $reddit-color;
}

.fa-rss,
.fa-rss-square {
  color: $rss-color;
}

.fa-soundcloud {
  color: $soundcloud-color;
}

.fa-stack-exchange,
.fa-stack-overflow {
  color: $stackoverflow-color;
}

.fa-tumblr,
.fa-tumblr-square {
  color: $tumblr-color;
}

.fa-twitter,
.fa-twitter-square {
  color: $twitter-color;
}

.fa-bluesky {
  color: $bluesky-color;
}

.fa-vimeo,
.fa-vimeo-square,
.fa-vimeo-v {
  color: $vimeo-color;
}

.fa-vine {
  color: $vine-color;
}

.fa-youtube {
  color: $youtube-color;
}

.fa-xing,
.fa-xing-square {
  color: $xing-color;
}


/* Academic Icons */ 

.ai-orcid,
.ai-orcid-square {
  color: $orcid-color;
}

.ai-google-scholar,
.ai-google-scholar-square {
	color: $google-scholar-color; 
}

.ai-doi,
.ai-doi-square {
	color: $doi-color; 
}

.ai-open-access,
.ai-open-access-square {
	color: $open-access-color;
} 
