/* ==========================================================================
   SINGLE PAGE/POST
   ========================================================================== */

#main {
  @include clearfix;
  margin-left: auto;
  // margin-top: calc(-#{$masthead-height} + 1em);
  // margin-top: -$masthead-height;
  margin-right: auto;
  // padding-top: $masthead-height;
  padding-left: 1em;
  padding-right: 1em;
  -webkit-animation: $intro-transition;
  animation: $intro-transition;
  max-width: 100%;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;

  @include breakpoint($x-large) {
    max-width: $max-width;
  }
}
.landing {
  #main {
    padding-top: 0em;
  }
}

.page {
  @include breakpoint($large) {
    float: right;
    width: calc(100% - #{$right-sidebar-width-narrow});
    padding-right: $right-sidebar-width-narrow-wide;
  }

  @include breakpoint($large-wide) {
    width: calc(100% - #{$right-sidebar-width-narrow-wide});
    padding-right: $right-sidebar-width;
  }

  @include breakpoint($x-large) {
    width: calc(100% - #{$right-sidebar-width});
    padding-right: $right-sidebar-width;
  }

  .page__inner-wrap {
    float: left;
    margin-top: 1em;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    clear: both;

    .page__content,
    .page__meta,
    .page__share {
      position: relative;
      float: left;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      clear: both;
    }
  }
}

.page__title {
  margin-top: 1em;
  line-height: 1;

  & + .page__meta {
    margin-top: -0.5em;
  }
}

.page__lead {
  font-family: $global-font-family;
  font-size: $type-size-4;
}

.page__content {
  h1, h2 {
    padding-bottom: 0.2em;
    border-bottom: 1px solid $border-color;
  }
  .post__content{
    
    @import "_icons";

    ol, ul {
      overflow: hidden;
      margin-bottom: 1em; 
    }
    
    li ul, li ol {
      margin-bottom: -0.5em !important;
    }
    
    blockquote {
      display: table;
      p:first-child{
        margin-top: .2em; 
      }
      p:last-child {
        margin-bottom: .2em; 
      }
    }
  }

	h1, h2, h3, h4, h5, h6 {
		.header-link {
			position: relative;
			left: 0.5em;
			opacity: 0;
			font-size: 0.8em;
      color: gray; 
			-webkit-transition: opacity 0.2s ease-in-out 0.1s;
			-moz-transition: opacity 0.2s ease-in-out 0.1s;
			-o-transition: opacity 0.2s ease-in-out 0.1s;
			transition: opacity 0.2s ease-in-out 0.1s;
		}

		&:hover .header-link {
			opacity: 1;
		}
	}

  p,
  li,
  dl {
    font-size: $type-size-5;
  }

  /* paragraph indents */
  p {
    margin: 0 0 $indent-var;

    /* sibling indentation*/
    @if $paragraph-indent == true {
      & + p {
        text-indent: $indent-var;
        margin-top: -($indent-var);
      }
    }
  }

  a:not(.btn) {
    &:hover {
      text-decoration: underline;

      img {
        box-shadow: 0 0 10px rgba(#000, 0.25);
      }
    }
  }

  dt {
    margin-top: 1em;
    font-family: $sans-serif;
    font-weight: bold;
  }

  dd {
    margin-left: 1em;
    font-family: $sans-serif;
    font-size: $type-size-5;
  }

  /* blockquote citations */
  blockquote + .small {
    margin-top: -1.5em;
    padding-left: 1.25rem;
  }
}

.page__hero {
  position: relative;
  margin-bottom: 2em;
  @include clearfix;
  -webkit-animation: $intro-transition;
  animation: $intro-transition;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;

  &--overlay {
    position: relative;
    margin-bottom: 2em;
    padding: 3em 0;
    @include clearfix;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-animation: $intro-transition;
    animation: $intro-transition;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;

    a {
      color: #fff;
    }

    .wrapper {
      padding-left: 1em;
      padding-right: 1em;
      padding-top: 7%;
      padding-bottom: 7%;

      @include breakpoint($x-large) {
        max-width: $x-large;
      }
    }

    .page__title,
    .page__meta,
    .page__lead,
    .btn {
      color: #fff;
      text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3),
                  -3px -3px 5px rgba(0, 0, 0, 0.3),
                  -3px 3px 5px rgba(0, 0, 0, 0.3),
                  3px -3px 5px rgba(0, 0, 0, 0.3);
    }

    .page__lead {
      max-width: $medium;
    }

    .page__title {
      font-size: $type-size-1;

      @include breakpoint($small) {
        font-size: 4em;
      }
    }
  }
}

.page__hero-image {
  width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

.page__hero-caption {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  padding: 2px 5px;
  color: #fff;
  font-family: $caption-font-family;
  font-size: $type-size-7;
  background: #000;
  text-align: right;
  z-index: 5;
  opacity: 0.5;
  border-radius: $border-radius 0 0 0;

  @include breakpoint($large) {
    padding: 5px 10px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}

/*
   Landing page
   ========================================================================== */

.landing {
  .nowrap {
    white-space: nowrap;
  }
  .page__hero {
    position: relative;
    margin-bottom: 2em;
    @include clearfix;
    -webkit-animation: $intro-transition;
    animation: $intro-transition;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;

    &--overlay {
      position: relative;
      margin-bottom: 1em;
      padding: 0em 0;
      @include clearfix;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      -webkit-animation: $intro-transition;
      animation: $intro-transition;
      -webkit-animation-delay: 0.25s;
      animation-delay: 0.25s;
      max-height: 1300px;

      a {
        color: #fff;
        text-decoration: none;
      }

      .wrapper {
        padding-left: 2em;
        padding-right: 1em;
        padding-top: 40%;
        padding-bottom: 0%;

        @include breakpoint($small) {
          // max-width: $large;
          padding-left: 3em;
          padding-top: 30%;
          padding-bottom: 5%;
        }

        @include breakpoint($large) {
          //  max-width: $large;
          padding-left: 5em;
          padding-top: 15%;
          padding-bottom: 20%;
        }
        @include breakpoint($x-large) {
          max-width: $x-large;
          padding-top: 20%;
        }
      }

      .page__title,
      .page__meta,
      .page__lead,
      .btn {
        color: #fff;
        text-shadow: 5px 5px 10px rgba(0,0,0,0.7);
        font-weight: normal;
      }

      .page__lead {
        max-width: none;
        font-size: $type-size-5;
        font-weight: bold;
        text-align: center;
        img.emoji {
            width: 1em;
        }

        @include breakpoint($small){
          font-size: calc(#{$type-size-4} - 0.0001em);
        }

        @include breakpoint($large){
          font-size: calc(#{$type-size-2} - 0.15em);
        }
      }

      .page__title {
        font-size: 3.5em;
        font-family: Mr Dafoe;
        margin-bottom: 0em;

        @include breakpoint($small) {
          font-size: 7em;
        }
        @include breakpoint($large) {
          font-size: 10em;
        }
      }
    }
  }

  .page__hero-image {
    width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
  }

  .page__hero-caption {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    padding: 2px 5px;
    color: #fff;
    font-family: $caption-font-family;
    font-size: $type-size-7;
    background: #000;
    text-align: right;
    z-index: 5;
    opacity: 0.5;
    border-radius: $border-radius 0 0 0;

    @include breakpoint($large) {
      padding: 5px 10px;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .page__content{
    .archive__item {
      margin: 0 0 .5em; 
    }

    p {
        font-size: $type-size-6;
    }
    .archive__item-body {
      width: 100%
    }
  }
}

/*
   Social sharing
   ========================================================================== */

.page__share {
  margin-top: 2em;
  padding-top: 1em;
  border-top: 1px solid $border-color;

  @include breakpoint(max-width $small) {
    .btn span {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}

.page__share-title {
  margin-bottom: 10px;
  font-size: $type-size-6;
  text-transform: uppercase;
}

/*
   Page meta
   ========================================================================== */

.page__meta {
  margin-top: 2em;
  color: $muted-text-color;
  font-family: $sans-serif;
  font-size: $type-size-6;

  p {
    margin: 0;
  }

  a {
    color: inherit;
  }
}

// adds a border if I want to the top of the tags block
// .page__meta-footer {
//     border-top: 1px solid $border-color;
//     padding-top: 2em;
//   }

.page__reading_time {
  margin-top: -0.5em;
  font-size: $type-size-8 !important;
}

.page__meta-title {
  margin-bottom: 10px;
  font-size: $type-size-6;
  text-transform: uppercase;
}

/*
   Page taxonomy
   ========================================================================== */

.page__taxonomy {
  .sep {
    display: none;
  }

  strong {
    margin-right: 10px;
  }
}

.page__taxonomy-item {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 8px;
  padding: 5px 10px;
  text-decoration: none;
  border: 1px solid mix(#000, $border-color, 25%);
  border-radius: $border-radius;

  &:hover {
    text-decoration: none;
    color: $link-color-hover;
  }
}

.taxonomy__section {
  margin-bottom: 2em;
  padding-bottom: 1em;

  &:not(:last-child) {
    border-bottom: solid 1px $border-color;
  }

  .archive__item-title {
    margin-top: 0;
  }

  .archive__subtitle {
    clear: both;
    border: 0;
  }

  + .taxonomy__section {
    margin-top: 2em;
  }
}

.taxonomy__title {
  margin-bottom: 0.5em;
  color: lighten($text-color, 60%);
}

.taxonomy__count {
  color: lighten($text-color, 50%);
}

.taxonomy__index {
  display: grid !important; 
  grid-column-gap: 2em;
  grid-template-columns: repeat(2, 1fr);
  margin: 1.414em 0;
  margin-bottom: initial !important; 
  padding: 0;
  font-size: 0.85em;
  list-style: none;


  .subtaxonomy__index {
    display: initial !important;
    // grid-column-gap: 2em;
    // grid-template-columns: repeat(2, 1fr);
    margin: 0.707em 0;
    margin-bottom: initial !important; 
    padding-left: 1em;
    // font-size: 0.75em;
    list-style: none;
  }

  @include breakpoint($large) {
    grid-template-columns: repeat(3, 1fr);
  }

  a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0.25em 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid $border-color;
  }
}

.tag__cloud {
  font-size: 0.80em !important;
  font-weight: 600; 
  a {
    color: black; 
    text-decoration: none;
  }
}

.back-to-top {
  display: block;
  clear: both;
  color: lighten($text-color, 50%);
  font-size: 0.6em;
  text-transform: uppercase;
  text-align: right;
  text-decoration: none;
}

/*
   Comments
   ========================================================================== */

.page__comments {
  float: left;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  clear: both;
}

.page__comments-title {
  margin-top: 2rem;
  margin-bottom: 10px;
  padding-top: 2rem;
  font-size: $type-size-6;
  border-top: 1px solid $border-color;
  text-transform: uppercase;
}

.page__comments-form {
  -webkit-transition: $global-transition;
  transition: $global-transition;

  &.disabled {
    input,
    button,
    textarea,
    label {
      pointer-events: none;
      cursor: not-allowed;
      filter: alpha(opacity=65);
      box-shadow: none;
      opacity: 0.65;
    }
  }
}

.comment {
  @include clearfix();
  margin: 1em 0;

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }
}

.comment__avatar-wrapper {
  float: left;
  width: 60px;
  height: 60px;

  @include breakpoint($large) {
    width: 100px;
    height: 100px;
  }
}

.comment__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  @include breakpoint($large) {
    width: 80px;
    height: 80px;
    padding: 5px;
    border: 1px solid $border-color;
  }
}

.comment__content-wrapper {
  float: right;
  width: calc(100% - 60px);
  font-size: $type-size-7;

  @include breakpoint($large) {
    width: calc(100% - 100px);
  }
}

.comment__author {
  margin: 0;

  a {
    text-decoration: none;
  }
}

.comment__date {
  @extend .page__meta;
  margin: 0;

  a {
    text-decoration: none;
  }
}

/*
   Related
   ========================================================================== */

.page__related {
  @include clearfix();
  float: left;
  margin-top: 2em;
  padding-top: 1em;
  border-top: 1px solid $border-color;

  @include breakpoint($large) {
    float: right;
    width: calc(100% - #{$right-sidebar-width-narrow});
  }

  @include breakpoint($large-wide) {
    width: calc(100% - #{$right-sidebar-width-narrow-wide});
  }

  @include breakpoint($x-large) {
    width: calc(100% - #{$right-sidebar-width});
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.page__related-title {
  margin-bottom: 10px;
  font-size: $type-size-6;
  text-transform: uppercase;
}

/*
   Wide Pages
   ========================================================================== */

.wide {
  .page {
    @include breakpoint($large) {
      padding-right: 0;
    }

    @include breakpoint($x-large) {
      padding-right: 0;
    }
  }

  .page__related {
    @include breakpoint($large) {
      padding-right: 0;
    }

    @include breakpoint($x-large) {
      padding-right: 0;
    }
  }
}
